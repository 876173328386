import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  debounce
} from '@mui/material'
import { CloseIcon } from '../../assets/icons'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'
import { trpc } from '../../core/trpc'
import { addBusinessDays, addHours, isWithinInterval, set } from 'date-fns'
import { toast } from 'react-hot-toast'
import { useQueryClient } from '@tanstack/react-query'
import { Followup_Type } from '@prisma/client'
import { Trash } from 'react-feather'
import { ContactGetContactByCompanyOutput, LeadsByUser } from '../../types/procedureOutputs'
import AddFollowUpDateSuggestions from '../../views/pipelines/leadDetails/AddFollowUpDateSuggestions'
import StyledCheckbox from '../StyledCheckbox'

type LeadFUInfo = {
  followup_type: Followup_Type | null
  followup_details: string | null
  is_urgent: boolean | null
  lead_id: string
  followup_date: string | null
}

type FollowUpModalProps = {
  lead?: LeadFUInfo
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  refetchFuProps?: { fuStartDate: string | undefined; take: number; skip: number }
  contact?: ContactGetContactByCompanyOutput | undefined
  setOpenEmailDrawer?: React.Dispatch<React.SetStateAction<boolean>>
  refetch?: () => void
}

const AddFollowUp = ({ refetchFuProps, lead, open, setOpen, contact, setOpenEmailDrawer, refetch }: FollowUpModalProps) => {
  const client = useQueryClient()
  const [followUpType, setFollowUpType] = useState<Followup_Type | null>('CALL')
  const [dateVal, setDateVal] = useState<Date | null>(addBusinessDays(new Date(), 1))
  const [timeVal, setTimeVal] = useState<Date | null>(set(new Date(), { hours: 10, minutes: 0 }))
  const [note, setNote] = useState('')
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const [search, setSearch] = useState('')
  const [isUrgent, setUrgent] = useState(lead?.is_urgent || false)
  const [reminder, setReminder] = useState(false)
  const [leadAutocomplete, setLeadAutocomplete] = useState<LeadsByUser['results'][number]>()
  const lead_id = lead ? lead.lead_id : leadAutocomplete?.id || ''
  const [suggestionMenuAnchorEl, setSuggestionMenuAnchorEl] = useState<HTMLElement>()

  useEffect(() => {
    if (lead?.followup_date) {
      setFollowUpType(lead?.followup_type || null)
      setNote(lead?.followup_details || '')
      setDateVal(new Date(lead?.followup_date))
      setTimeVal(new Date(lead?.followup_date))
    }
  }, [lead])

  const { data: leads } = trpc.lead.getLeadsBySearch.useQuery({ search })
  const debouncedSearch = useMemo(() => debounce((input: string) => setSearch(input), 300), [])
  const createMutation = trpc.followup.setFollowup.useMutation({
    onSuccess: () => {
      toast.success('Follow Up successfully created')
      client.refetchQueries({ queryKey: [['lead', 'getLeadById'], { input: { id: lead_id }, type: 'query' }] })
      refetchFuProps && client.refetchQueries({ queryKey: [['followup', 'getMyFollowupLeads'], { input: refetchFuProps, type: 'query' }] })
      refetch && refetch()
      setOpen(false)
    },
    onError: (e) => {
      toast.error(e.message)
    }
  })

  const deleteMutation = trpc.followup.clearFollowup.useMutation({
    onSuccess: () => {
      toast.success('Follow up deleted')
      client.refetchQueries({ queryKey: [['lead', 'getLeadById'], { input: { id: lead_id }, type: 'query' }] })
      refetchFuProps && client.refetchQueries({ queryKey: [['followup', 'getMyFollowupLeads'], { input: refetchFuProps, type: 'query' }] })
      refetch && refetch()
      setOpen(false)
    },
    onError: (e) => {
      toast.error(e.message)
    }
  })

  const disabled = !followUpType || !dateVal || !timeVal
  return (
    <Dialog open={open}>
      <Box className="p-6 w-[400px]">
        <Box className="flex justify-between items-center mb-5">
          <Typography className="text_xl_medium text-grey-900">Next Follow Up</Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon fontSize="small" className="text-grey-700" />
          </IconButton>
        </Box>
        <Box className="flex flex-col gap-4">
          {!lead && (
            <Autocomplete
              placeholder="Search Leads"
              options={leads?.results || []}
              onInputChange={(_, newInputValue) => debouncedSearch(newInputValue)}
              onChange={(_e, v) => {
                if (v) {
                  setLeadAutocomplete(v)
                  if (v?.followup_date) {
                    setFollowUpType(v?.followup_type || null)
                    setNote(v?.followup_details || '')
                    setDateVal(new Date(v?.followup_date))
                    setTimeVal(new Date(v?.followup_date))
                  }
                }
              }}
              filterOptions={(x) => x}
              value={leadAutocomplete || null}
              getOptionLabel={(option) => `${option.company.name}-${option.name}`}
              renderInput={(params) => <TextField {...params} />}
            />
          )}
          <Box className="flex justify-between">
            <RadioGroup row onChange={(_, v) => setFollowUpType(v as typeof followUpType)} value={followUpType}>
              {['Email', 'Call'].map((type) => (
                <FormControlLabel
                  key={type}
                  value={type.toUpperCase() as Followup_Type}
                  control={<Radio />}
                  label={
                    <Typography className={clsx({ 'text-grey-900': followUpType === type, 'text-grey-600': followUpType !== type })}>
                      {type}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </Box>
          <DatePicker
            className="cy-date"
            slotProps={{
              textField: { onClick: (e) => setSuggestionMenuAnchorEl(e.currentTarget) },
              inputAdornment: {
                onClick: (e) => {
                  e.stopPropagation()
                  setOpenDatePicker(true)
                }
              }
            }}
            disablePast
            value={dateVal}
            open={openDatePicker}
            onClose={() => setOpenDatePicker(false)}
            onChange={(val) => {
              setDateVal(val)
              setOpenDatePicker(false)
            }}
          />
          <TimePicker
            className="cy-time"
            value={timeVal}
            onChange={(val) => setTimeVal(val)}
            timeSteps={{ minutes: 1 }}
            views={['hours', 'minutes']}
          />
          <FormControlLabel
            onChange={(_, checked) => setUrgent(checked)}
            label="Urgent"
            control={<StyledCheckbox data-cy="urgentCheckbox" checked={isUrgent} />}
          />
          <FormControlLabel
            onChange={(_, checked) => setReminder(checked)}
            label="Email Reminder"
            disabled={isWithinInterval(timeVal || new Date(), { start: new Date(), end: addHours(new Date(), 1) })}
            control={<StyledCheckbox data-cy="reminderCheckbox" checked={reminder} />}
          />
          <TextField
            data-cy="followupDetails"
            className="mt-4"
            placeholder="Enter Note..."
            fullWidth
            value={note}
            onChange={(e) => setNote(e.target.value)}
            variant="standard"
            InputProps={{ disableUnderline: true }}
          />
          <Divider className="my-6" />
          <Box className="flex justify-end gap-3">
            {!lead?.followup_date && !leadAutocomplete?.followup_date && (
              <Button onClick={() => setOpen(false)} variant="outlined">
                Cancel
              </Button>
            )}
            {(lead?.followup_date || leadAutocomplete?.followup_date) && (
              <LoadingButton
                data-cy="completeButton"
                loading={deleteMutation.isLoading}
                onClick={() => deleteMutation.mutate({ lead_id: lead_id || '' })}
                variant="outlined"
                startIcon={<Trash size="16px" />}
              >
                Complete Follow Up
              </LoadingButton>
            )}
            <LoadingButton
              data-cy="saveButton"
              disabled={disabled}
              loading={createMutation.isLoading}
              onClick={() => {
                if (dateVal && timeVal) {
                  const due_date = set(dateVal, {
                    hours: timeVal.getHours(),
                    minutes: timeVal.getMinutes(),
                    seconds: timeVal.getSeconds()
                  })
                  createMutation.mutate({
                    lead_id: String(lead_id),
                    due_date,
                    type: followUpType as Followup_Type,
                    details: note,
                    is_urgent: isUrgent,
                    reminder: reminder
                  })
                }
              }}
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      {Boolean(suggestionMenuAnchorEl) && (
        <AddFollowUpDateSuggestions
          anchorEl={suggestionMenuAnchorEl}
          onClose={() => setSuggestionMenuAnchorEl(undefined)}
          onChange={(date) => {
            setDateVal(date)
            setTimeVal(date)
            setSuggestionMenuAnchorEl(undefined)
          }}
        />
      )}
    </Dialog>
  )
}

export default AddFollowUp
