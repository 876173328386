import React, { useState } from 'react'
import { ChevronRight, Clock, Mail } from 'react-feather'
import { Typography, Chip, Box, IconButton } from '@mui/material'
import { LeadsByUserResultOutput } from '../../../../types/procedureOutputs'
import { format } from 'date-fns'
import { useNavigate } from 'react-router'
import CallAndCallFeedback from '../../../../components/CallAndCallFeedback'
import NewEmailDrawer from '../../../../components/email/NewEmailDrawer'
import toast from 'react-hot-toast'
import { trpc } from '../../../../core/trpc'
import { ErrorOutlineOutlinedIcon } from '../../../../assets/icons'
import { StageChip } from '../../../../components/Chips'
import { CircleOutlined } from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import qs from 'qs'
import { useSession } from 'next-auth/react'
import { RuleType } from 'react-querybuilder'
import { uniq } from 'ramda'

const FollowUpItem = ({ date, followUps, refetch }: { date: string; followUps: LeadsByUserResultOutput[] | undefined; refetch: () => void }) => {
  const navigate = useNavigate()
  const { data } = useSession()

  const [openEmailDrawer, setOpenEmailDrawer] = useState(false)
  const deleteMutation = trpc.followup.clearFollowup.useMutation({
    onSuccess: () => {
      refetch()
      toast.success('Follow up deleted')
    },
    onError: (e) => {
      toast.error(e.message)
    }
  })
  const confirm = useConfirm()
  return (
    <Box key={date}>
      <Typography
        className={`text_md_normal text-grey-500 my-3 pl-4 border-solid border-y-0 border-r-0 border-l-4 ${followUps?.[0]?.followup_date && followUps?.[0]?.followup_date < new Date().toISOString() ? ' border-error-400' : 'border-[#A6A9FC]'
          }`}
      >
        {followUps?.[0]?.followup_date && format(new Date(followUps?.[0]?.followup_date), 'EEEE, MMM dd')}
      </Typography>
      {followUps?.map((fu) => (
        <Box className="flex justify-between border border-solid rounded-lg border-grey-300 p-2 mb-2" key={fu.id}>
          <Box>
            <Box className="flex flex-wrap items-center">
              <IconButton onClick={() => confirm({
                title: 'Mark as complete',
                description: `Are you sure you want to mark this follow up as complete?`,
                cancellationText: 'No',
                confirmationText: 'Yes'
              }).then(() => { deleteMutation.mutate({ lead_id: fu.id || '' }) })} className='-ml-3'>
                <CircleOutlined />
              </IconButton>
              <Typography className="text_md_medium text-grey-700">{fu.followup_details}</Typography>
              {fu.followup_urgent && (
                <Box className="p-1 bg-error-400 text-white rounded-full flex items-center justify-center">
                  <ErrorOutlineOutlinedIcon fontSize="small" />
                </Box>
              )}
              <Chip
                onClick={() => {
                  const filterToPass: RuleType[] = [{ field: 'has_followup', value: true, operator: '=' }]
                  const filter = { stages: uniq(followUps.map(f => f.stage)), filters: filterToPass, sortBy: { days_in_stage: 'desc' }, assignedToIds: [data?.user.id] }
                  const queryParams = qs.stringify(filter, { addQueryPrefix: true })
                  navigate(`/pipeline/${fu.stage.pipeline_id}/lead/${fu.id}${queryParams}`, { state: { from: '/' } })
                }}
                size="small"
                label={`${fu.company.name} - ${fu.name}`}
              />
							<StageChip 
								color={fu.stage.color} 
								textColor={fu.stage.text_color || 'white'} 
								stageId={fu.stage.id} 
								stageName={fu.stage.name} 
							/>
            </Box>
            <Box className="flex gap-2 mt-2">
              <Clock className="text-grey-500 w-3 h-3 self-center" />
              <Typography className="text-grey-500 text_md_normal self-center">
                {fu.followup_date && format(new Date(fu.followup_date), 'hh:mm aa')}
              </Typography>
              {fu.followup_type === 'CALL' ? (
                <CallAndCallFeedback companyId={fu.company.id} leadId={fu.id} />
              ) : (
                <Chip
                  className={'px-1 bg-error-50 text-error-500 cursor-pointer'}
                  avatar={<Mail className="text-error-500" />}
                  size="small"
                  label={fu.followup_type || ''}
                  onClick={() => setOpenEmailDrawer(true)}
                />
              )}
            </Box>
          </Box>
          <Box className="self-center">
            <IconButton size="small" onClick={() => {
              const filterToPass: RuleType[] = [{ field: 'has_followup', value: true, operator: '=' }]
              const filter = { stages: uniq(followUps.map(f => f.stage)), filters: filterToPass, sortBy: { days_in_stage: 'desc' }, assignedToIds: [data?.user.id] }
              const queryParams = qs.stringify(filter, { addQueryPrefix: true })
              navigate(`/pipeline/${fu.stage.pipeline_id}/lead/${fu.id}${queryParams}`, { state: { from: '/' } })
            }}>
              <ChevronRight />
            </IconButton>
          </Box>
          {openEmailDrawer && (
            <NewEmailDrawer
              open={true}
              setOpen={setOpenEmailDrawer}
              leadIdPassedIn={fu.id}
              markFollowUpComplete={() => {
                deleteMutation.mutate({ lead_id: fu.id || '' })
              }}
            />
          )}
        </Box>
      ))
      }
    </Box >
  )
}
export default FollowUpItem
